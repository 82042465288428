export default [
    {
        id: 1,
        title: '生产基地',
        text:' 公司生产基地位于中国干涸内陆盐湖——察尔汗盐湖，是中国目前钾肥工业生产基地，隶属青海盐湖工业股份有限公司。盐湖股份是青海省四大优势资源型企业之一，也是柴达木循环经济试验区内的龙头骨干企业。盐湖文通生产基地将以盐湖资源为基础，以柴达木盆地丰富的煤炭、天然气、石灰石等资源为依托，形成具有循环经济、节能减排、可持续发展理念的完整工业体系',
        img:require('../../../assets/images/scjd02_img.png')
    }, {
        id: 2,
        title: '港口仓储',
        text:'公司下设上海、天津、湖南、山西、山东五大仓库，真正做到东、南、西、北全网覆盖。同时公司依托所处地理优势，拥有上海、天津两大港口资源，配以完备的仓储中心，真正做到“想客户所想，急客户所急”，为客户提供快速、高效的物流服务。',
        img:require('../../../assets/images/gkcc01_img.png'),
    }
];