<template>
  <div class="banner">
    <div class="c-width">
      <div class="h-box">
        <h3>{{ title }}</h3>
        <h6>{{ subTitle }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },
};
</script>
<style scoped>
.banner {
  background: url(../assets//images/top_img.png) no-repeat;
  background-size: cover;
  height: 350px;
  width: 100%;
}
.banner .c-width {
  position: relative;
}
.banner .c-width .h-box {
  position: absolute;
  top: 134px;
  right: 40px;
  text-align: right;
}
.banner .c-width .h-box h3 {
  color: #1c3b95;
  font-size: 45px;
  font-weight: 700;
}
.banner .c-width .h-box h6 {
  font-size: 25px;
  color: #9f9f9f;
}
</style>